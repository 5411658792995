import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { ProductPage, ProductPageProps } from '../../components/shop/pdp/ProductPage'

export type ProductPageTemplateProps = ProductPageProps

const ProductPageTemplate: FC<ProductPageTemplateProps> = ({ pageContext, data }) => {
  return <ProductPage data={data} pageContext={pageContext} />
}

export const query = graphql`
  query productPageTemplate($sku: String!) {
    sanityParentProduct(sku: { eq: $sku }) {
      ...sanityParentProduct
    }
  }
`

export default ProductPageTemplate

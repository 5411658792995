import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { ProductLabelStatus } from '../../../../graphql/search'
import useSiteMetadata from '../../../../hooks/useSiteMetadata'
import {
  getVariantsKeyFromAttribute,
  Product,
  UseProducts,
  VariantAttributeKey,
} from '../../../../lib/products'
import useSanityImage from '../../../../lib/sanity/hooks/useSanityImage'
import { isNotNull } from '../../../../utils/collectionTools'
import Link from '../../../global/Link'
import LabelStatusIcon from '../../../LabelStatusIcon'
import LoginToSeePrice from '../../LoginToSeePrice'

import productImagePlaceholder from '../../../../img/product-image-placeholder.png'

const StyledLabelStatusIcon = styled(LabelStatusIcon)`
  position: absolute;
  margin-left: 5px;
  margin-top: 3px;
`

export interface ItemBoxProps {
  className?: string
  item: Product
  page?: number
  effectCallback?: UseProducts['pagination']['actions']['lastOfPageEffectCallback'] | null
}

// TODO: Check if isAuthenticated is needed after M2 update to remove prices for guests
// TODO: Return prices when done correctly
const ItemBox: FC<ItemBoxProps> = ({ className, item, page, effectCallback }) => {
  const { featureFlags } = useSiteMetadata() ?? {}
  const itemBoxRef = useRef<HTMLDivElement>(null)

  const variantAttributes = (item.variants?.summary.attributes || []).map<VariantAttributeKey>(
    (attribute) => getVariantsKeyFromAttribute(attribute)
  )

  const sanityImageUrl = useSanityImage(
    { sourceJson: item.sanityImageJson || undefined },
    {
      dpr: 2,
      fit: 'max',
      // set width only to avoid crop bug: https://github.com/sanity-io/image-url/issues/32
      width: 225,
    }
  )
  const itemUrl = sanityImageUrl || productImagePlaceholder

  useEffect(() => {
    if (effectCallback && page) {
      return effectCallback(itemBoxRef.current, page)
    }
    // effectCallback missing is intentional to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemBoxRef.current, page])

  return (
    <div className={className} ref={itemBoxRef}>
      <Link className="itemImageAndName" to={`/products/${item.urlKey}/`}>
        {featureFlags?.productLabelStatus && isNotNull(item.labelStatus) && (
          <StyledLabelStatusIcon isLabelPresent={item.labelStatus === ProductLabelStatus.Present} />
        )}
        <img alt={item.name || undefined} src={itemUrl} />
        <h4>{item.name.toUpperCase()}</h4>
      </Link>
      <div className="item-details">
        <LoginToSeePrice />
        {variantAttributes.map((attributeKey) => {
          const values = item.variants?.summary.values[attributeKey] || []
          return (
            values.length > 0 && (
              <p
                key={`${item.urlKey}-${attributeKey}`}
                className="small-paragraph small-margin-bottom"
              >
                {values.join(', ')}
              </p>
            )
          )
        })}
      </div>
    </div>
  )
}

ItemBox.defaultProps = {
  className: '',
  page: 1,
  effectCallback: null,
}

const StyledItemBox = styled(ItemBox)(({ theme }) => ({
  backgroundColor: theme.colors.grayscale.white,
  border: `1px solid ${theme.colors.coolGray.cool150}`,
  display: 'flex',
  flex: '1 0 100%',
  flexDirection: 'column',
  hyphens: 'auto',
  justifyContent: 'flex-start',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  boxSizing: 'border-box',
  a: {
    color: theme.colors.grayscale.black,
    textDecoration: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  h4: {
    padding: '15px 15px 8px',
  },
  img: {
    height: '225px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  '.itemImageAndName': {
    display: 'flex',
    flexDirection: 'column',
    img: {
      alignSelf: 'center',
    },
  },
  '.item-details': {
    padding: '0 15px 15px',
  },
  '.tagline': {
    textTransform: 'uppercase',
  },
  '@media(min-width: 375px)': {
    flex: '0 0 50%',
    width: '50%',
  },
  '@media(min-width: 600px)': {
    flex: '0 0 calc(100%/3)',
    width: 'calc(100%/3)',
  },
  '@media(min-width: 768px)': {
    flex: '0 0 25%',
    marginBottom: '20px',
    width: '25%',
  },
  // Match the Bootstrap breakpoint used by the header:
  '@media(min-width: 992px)': {
    // Select the first ItemBox in each row:
    '&:nth-of-type(4n + 1)': {
      borderBottomLeftRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    // Select the last ItemBox in each row:
    '&:nth-of-type(4n)': {
      borderBottomRightRadius: '4px',
      borderTopRightRadius: '4px',
    },
  },
}))

export default StyledItemBox

import { useAuth0 } from '@auth0/auth0-react'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import useSiteMetadata from '../../hooks/useSiteMetadata'
import { useDispensaryContext } from '../../lib/dispensaries'
import LoginLink from '../global/LoginLink'
import RegistrationLink from '../global/RegistrationLink'

export interface LoginToSeePriceProps {
  className?: string
  isProductPage?: boolean
}

const LoginButtonLink = styled(LoginLink)(
  ({ theme }) => css`
    color: ${theme.colors.component.link};

    &:focus {
      outline: none;
    }
  `
)

const RegisterButtonLink = styled(RegistrationLink)(
  ({ theme }) => css`
    color: ${theme.colors.component.link};
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;

    &:focus {
      outline: none;
    }
  `
)

const LoginToSeePrice: FC<LoginToSeePriceProps> = ({ className, isProductPage }) => {
  const { isAuthenticated } = useAuth0()
  const dispensary = useDispensaryContext()
  const { featureFlags } = useSiteMetadata() ?? {}
  const anonymousCart = featureFlags?.anonymousCart ?? false

  if (isAuthenticated || dispensary || anonymousCart) {
    return null
  }

  return (
    <h5 className={`${className} roboto`}>
      {isProductPage ? (
        <>
          Please <LoginButtonLink>log in</LoginButtonLink> or{' '}
          <RegisterButtonLink>create an account</RegisterButtonLink> to see price or purchase this
          item.
        </>
      ) : (
        <>
          <LoginButtonLink>Log in</LoginButtonLink> or{' '}
          <RegisterButtonLink>create an account</RegisterButtonLink> to purchase
        </>
      )}
    </h5>
  )
}

LoginToSeePrice.defaultProps = {
  className: '',
}

const StyledLoginToSeePrice = styled(LoginToSeePrice)``

export default StyledLoginToSeePrice
